import { publicClarakmConfig as clarakmConfig, MaintenanceMode } from "@teslagov/clarakm-env-js";
import { ResetTitle } from "@teslagov/clarakm-js-react";
import React from "react";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "react-router-redux";
import { AppContainer, getHistory, getStore } from "./app";
import AuthorizeContainer from "./authorize/AuthorizeContainer";
import ForgotUsernameContainer from "./forgot-username/ForgotUsernameContainer";
import { HelpContainer } from "./help";
import { LoginNavContainer } from "./login-nav";
import MaintenanceContainer from "./maintenance/MaintenanceContainer";
import { PasswordResetContainer, PasswordResetRequestContainer } from "./password-reset";

const maintenanceMode = clarakmConfig.app.maintenance.mode;

export default (
  <ConnectedRouter history={getHistory()} store={getStore()}>
    <AppContainer>
      <ResetTitle />
      {maintenanceMode !== MaintenanceMode.On && (
        <Switch>
          <Route exact name="login" path={[ "/login", "/oidc", "/oidc/idp", "/logout", "/" ]} component={LoginNavContainer} />
          <Route exact name="authorize" path="/authorize" component={AuthorizeContainer} />
          <Route exact name="help" path="/help" component={HelpContainer} />
          <Route exact name="forgot-username" path="/forgot-username" component={ForgotUsernameContainer} />
          <Route exact name="change-password" path="/change-password" component={PasswordResetRequestContainer} />
          <Route exact name="forgot-password" path="/forgot-password" component={PasswordResetRequestContainer} />
          <Route exact name="password-confirm" path="/password-confirm" component={PasswordResetContainer} />
          <Route exact name="password-confirm" path="/password-confirm/:code" component={PasswordResetContainer} />
          <Route status={404} component={()=>(
            <div>Sorry, that page cannot be found.</div>
          )} />
        </Switch>
      )}

      {maintenanceMode === MaintenanceMode.On && <Route component={MaintenanceContainer} />}
    </AppContainer>
  </ConnectedRouter>
);
