import React from "react";
import { Field, reduxForm } from "redux-form";
import { nameof } from "../app/constants";
import { Captcha } from "../authorize/Captcha";
import { isEmail, renderField } from "../redux-form";
import { required } from "@teslagov/clarakm-js-api";

const FORM_NAME = "forgotUsernameForm";

export type ForgotUsernameFormData = FormData & {
  email: string;
};

type Props = {
  isBusy: boolean;
  storeCaptchaV2Token: (token: string) => void;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

const emailFormValidation = [ required({ messages: { label: "email" } }), isEmail ];

export const ForgotUsernameForm = reduxForm<ForgotUsernameFormData, Props>({
  form: FORM_NAME,
  touchOnChange: false,
})(props => {
  const { handleSubmit, valid, isBusy, storeCaptchaV2Token, hasFailedV3Captcha, captchaV2Token } = props;

  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <Field<any> name={nameof<ForgotUsernameFormData>("email")} fieldName={nameof<ForgotUsernameFormData>("email")} label="Email Address" validate={emailFormValidation} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />
      <Captcha hasFailedV3Captcha={hasFailedV3Captcha} storeCaptchaV2Token={storeCaptchaV2Token} />
      <div className="text-center mt-5 mb-5">
        <button disabled={!valid || isBusy || (hasFailedV3Captcha && !captchaV2Token)} type="submit" className="btn btn-primary">
          Recover Username
        </button>
      </div>
    </form>
  );
});
