import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {clarakmConfig} from "@teslagov/clarakm-env-js";

export const Captcha = (props: Props) => {
  const { hasFailedV3Captcha, storeCaptchaV2Token } = props;

  if (!hasFailedV3Captcha) {
    return null;
  }
  else {
    return (
      <div className="pt-3">
        <ReCAPTCHA
          sitekey={clarakmConfig.login.captcha.publicKeyV2}
          onChange={token => storeCaptchaV2Token(token)}
          onExpired={() => storeCaptchaV2Token(undefined)}
        />
      </div>
    );
  }
};

type Props = {
  hasFailedV3Captcha: boolean;
  storeCaptchaV2Token: (token: string) => any;
}
