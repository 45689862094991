import { publicClarakmConfig as clarakmConfig } from "@teslagov/clarakm-env-js";
import { claraToastr } from "./constants";

export function loadRecaptcha() {
  if (clarakmConfig.login.captcha.enabled) {
    const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${clarakmConfig.login.captcha.publicKeyV3}`;
    const isScriptExist = document.getElementById(clarakmConfig.login.captcha.publicKeyV3);

    if (!isScriptExist) {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.src = scriptUrl;
      script.id = clarakmConfig.login.captcha.publicKeyV3;
      document.body.appendChild(script);
    }
  }
}

// attempts 'invisible' v3 captcha, tries v2 captcha checkbox as backup
export function executeCallbackWithCaptchaIfNecessary(action: string, callback: (token: string) => any, hasFailedCaptchaV3: boolean, captchaV2Token: string) {
  const tempWindow = window as any;
  const isCaptchaRequired = clarakmConfig.login.captcha.enabled;

  if (isCaptchaRequired) {
    //error handling if browser fails to load grecaptcha
    if(!tempWindow.grecaptcha) {
      claraToastr.error("Recaptcha Error!", "Error processing recaptcha");
    }
    else {
      if (hasFailedCaptchaV3) {
        callback(captchaV2Token);
      }
      else {
        verifyV3Captcha(tempWindow, callback, action);
      }
    }
  }
  else {
    callback(undefined);
  }
}

function verifyV3Captcha(tempWindow: any, callback: (token: string) => any, action: string) {
  tempWindow.grecaptcha.ready(() => {
    tempWindow.grecaptcha
      .execute(clarakmConfig.login.captcha.publicKeyV3, { action })
      .then(token => callback(token))
      .catch(error => claraToastr.error("Recaptcha Error!", "Could not execute grecaptcha: " + error));
  });
}
