export const TAB_CHANGE = "auth/TAB_CHANGE";

export const TAB_LOGIN = "login";
export const TAB_REQUEST_ACCOUNT = "request-account";

type ReducerState = {
  currentTab: string;
};

const initialState: ReducerState = {
  currentTab: TAB_LOGIN,
};

export default function auth(state = initialState, action): ReducerState {
  switch (action.type) {
    case TAB_CHANGE:
      return {
        ...state,
        currentTab: action.tab,
      };
    default:
      return state;
  }
}

export const changeTabToLogin = (): { type: string; tab: string } => ({ type: TAB_CHANGE, tab: TAB_LOGIN });

export const changeTabToRequestAccount = (): { type: string; tab: string } => ({ type: TAB_CHANGE, tab: TAB_REQUEST_ACCOUNT });
