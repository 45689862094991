import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { required } from "@teslagov/clarakm-js-api";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { Errors } from "../../app/components/Errors";
import { Captcha } from "../../authorize/Captcha";
import { isEmail, maxLength, renderField, renderTextArea } from "../../redux-form";
import { FORM_FIELD_NAMES, FORM_NAME } from "../constants";
import { storeCaptchaV2Token } from "../duck";
import { captchaV2TokenSelector, hasFailedV3CaptchaSelector, loadingSelector } from "../selectors";

const maxLength255 = maxLength(255);

class HelpForm extends Component {
  requiredValidator = required({ messages: { label: "" } });

  render() {
    const { errors, submitting, handleSubmit, hasFailedV3Captcha, captchaV2Token, actions: { storeCaptchaV2Token } } = this.props;
    const { requiredValidator } = this;
    const disabled = (hasFailedV3Captcha && !captchaV2Token) || errors || submitting;

    return (
      <>
        <h4 className="card-title">
          {t("help.title")}
        </h4>

        {errors && <Errors errors={errors} />}

        <form onSubmit={handleSubmit} className="mt-2">
          <Field maxLength={255} name={FORM_FIELD_NAMES.name} fieldName={FORM_FIELD_NAMES.name} label={t("help.labels.name")} validate={[ requiredValidator, maxLength255 ]} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" autoFocus={true} />
          <Field maxLength={255} name={FORM_FIELD_NAMES.emailAddress} fieldName={FORM_FIELD_NAMES.emailAddress} label={t("labels.emailAddress")} validate={[ requiredValidator, isEmail, maxLength255 ]} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />
          <Field maxLength={255} name={FORM_FIELD_NAMES.description} fieldName={FORM_FIELD_NAMES.description} label={t("help.labels.message")} validate={requiredValidator} component={renderTextArea} inputClassNames="mb-0" labelClassnames="required-label" rows={5} />
          <Captcha hasFailedV3Captcha={hasFailedV3Captcha} storeCaptchaV2Token={storeCaptchaV2Token} />
        </form>

        <div className="d-flex justify-content-center mt-5">
          <button onClick={handleSubmit} disabled={disabled} type="submit" className="btn btn-primary">
            <span className="mx-auto">
              {t("actions.submit")}
            </span>
          </button>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <Link to="/login">
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            {t("actions.goBack")}
          </Link>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasFailedV3Captcha: hasFailedV3CaptchaSelector,
  captchaV2Token: captchaV2TokenSelector,
  submitting: loadingSelector,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      storeCaptchaV2Token: storeCaptchaV2Token,
    },
    dispatch
  )
});

export default reduxForm({
  form: FORM_NAME,
})(
  connect(mapStateToProps, mapDispatchToProps)(HelpForm)
);
