export const NAME = "request-account";
export const FORM_NAME = "requestAccountForm";

export const FORM_FIELD_NAMES = {
  firstName: "firstName",
  lastName: "lastName",
  emailAddress: "emailAddress",
  citizenship: "citizenship",
  language: "language",
  reason: "reason",
  registrationReason: "registrationReason",
  specifiedRegistrationReason: "specifiedRegistrationReason",
};

export const TOASTR_OPTIONS = {
  timeOut: 0,
  removeOnHover: false,
  showCloseButton: true,
};
