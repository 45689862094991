import React from "react";

export const ApiErrors = [{
  code: 1003,
  title: "Password Expired",
  options: {
    component: (
      <span>
        Your password has expired. We just sent you an email with instructions on establishing a new password.
      </span>
    ),
    timeOut: 0,
    removeOnHover: false,
  },
}, {
  code: 7006,
  title: "Invalid Reset Code",
  options: {
    component: (
      <span>
        Your password reset code is invalid. Please verify that you have entered the code correctly and try again. If this issue persists, you may <a href="/login/forgot-password">request a new code</a>.
      </span>
    ),
    timeOut: 0,
    removeOnHover: false,
  },
}, {
  code: 7008,
  title: "Expired Reset Code",
  options: {
    component: (
      <span>
        Your password reset code has expired. Please <a href="/login/forgot-password">request a new code</a> in order to reset your password.
      </span>
    ),
    timeOut: 0,
    removeOnHover: false,
  },
}];
