import React from "react";
import classNames from "classnames";

const TextInput = ({ maxLength, autoFocus, name, inputDivClassNames, inputClassNames, type, input, autocomplete, readOnly }) => {
  return (
    <div className={classNames("mb-2", inputClassNames)}>
      <input autoComplete={autocomplete} maxLength={maxLength} autoFocus={autoFocus} id={`${name}-input`} className={classNames("form-control", inputClassNames)} aria-describedby={`${name}-label`} type={type || "text"} {...input} readOnly={readOnly} />
    </div>
  );
};

export default TextInput;
