
export const SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST = "forgot-password/SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST";
export const SEND_RESET_PASSWORD_CODE_VIA_EMAIL_SUCCESS = "forgot-password/SEND_RESET_PASSWORD_CODE_VIA_EMAIL_SUCCESS";
export const SEND_RESET_PASSWORD_CODE_VIA_EMAIL_FAILURE = "forgot-password/SEND_RESET_PASSWORD_CODE_VIA_EMAIL_FAILURE";

export const CAPTCHA_V2_TOKEN = "forgot-password/CAPTCHA_V2_TOKEN";
export const CAPTCHA_V3_FAILURE = "forgot-password/CAPTCHA_V3_FAILURE";

export const RESET_PASSWORD_WITH_CODE_REQUEST = "forgot-password/RESET_PASSWORD_WITH_CODE_REQUEST";
export const RESET_PASSWORD_WITH_CODE_SUCCESS = "forgot-password/RESET_PASSWORD_WITH_CODE_SUCCESS";
export const RESET_PASSWORD_WITH_CODE_FAILURE = "forgot-password/RESET_PASSWORD_WITH_CODE_FAILURE";

export const SHOW_SUCCESS_MODAL = "forgot-password/SHOW_SUCCESS_MODAL";

export const HIDE_SUCCESS_MODAL = "forgot-password/HIDE_SUCCESS_MODAL";

export type ReducerState = {
  loading: boolean;
  errors: any[];
  isModalShowing: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

const initialState: ReducerState = {
  loading: false,
  errors: null,
  isModalShowing: false,
  hasFailedV3Captcha: false,
  captchaV2Token: undefined,
};

export default function auth(state = initialState, action): ReducerState {
  switch (action.type) {
    case SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case SEND_RESET_PASSWORD_CODE_VIA_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case SEND_RESET_PASSWORD_CODE_VIA_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case RESET_PASSWORD_WITH_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case RESET_PASSWORD_WITH_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case RESET_PASSWORD_WITH_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: true,
      };
    case HIDE_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: false,
      };
    case CAPTCHA_V2_TOKEN:
      return {
        ...state,
        loading: false,
        captchaV2Token: action.token,
      };
    case CAPTCHA_V3_FAILURE:
      return {
        ...state,
        loading: false,
        hasFailedV3Captcha: true,
        captchaV2Token: undefined,
      };
    default:
      return state;
  }
}

export const captchaV3Failure = (): { type: string } => ({ type: CAPTCHA_V3_FAILURE });

export const storeCaptchaV2Token = (token: string): { type: string, token: string } => ({ type: CAPTCHA_V2_TOKEN, token });

export const sendResetPasswordCodeViaEmailRequest = (token: string): { type: string, token: string } => ({ type: SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST, token });

export const sendResetPasswordCodeViaEmailSuccess = (): { type: string } => ({ type: SEND_RESET_PASSWORD_CODE_VIA_EMAIL_SUCCESS });

export const sendResetPasswordCodeViaEmailFailure = (): { type: string } => ({ type: SEND_RESET_PASSWORD_CODE_VIA_EMAIL_FAILURE });

export const resetPasswordWithCodeRequest = (payload) => ({ type: RESET_PASSWORD_WITH_CODE_REQUEST, payload });

export const resetPasswordWithCodeSuccess = (): { type: string } => ({ type: RESET_PASSWORD_WITH_CODE_SUCCESS });

export const resetPasswordWithCodeFailure = (errorMessage): { type: string } => ({ type: RESET_PASSWORD_WITH_CODE_FAILURE });

export const showModal = (): { type: string } => ({ type: SHOW_SUCCESS_MODAL });

export const hideModal = (): { type: string } => ({ type: HIDE_SUCCESS_MODAL });
