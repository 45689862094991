import { logger } from "@teslagov/clarakm-js-api";
import { initApiErrors, initTranslation, registerCKMGlobal } from "@teslagov/clarakm-js-react";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "url-search-params-polyfill";
import { RootContainer, getStore } from "./app";
import { claraToastr } from "./app/constants";
import { ApiErrors } from "./login/api-errors";
import router from "./router";

initApiErrors(claraToastr, ...ApiErrors);
registerCKMGlobal();

initTranslation([ "login" ]).finally(() => {
  logger.info("REACT_APP_BUILD_NUMBER =", process.env.REACT_APP_BUILD_NUMBER);

  ReactDOM.render(<RootContainer router={router} store={getStore()} />, document.getElementById("root"));
});
