import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { clearFields, destroy, startSubmit, stopSubmit } from "redux-form";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { claraToastr } from "../app/constants";
import { changeTabToLogin } from "../login-nav/duck";
import { selfRegister as selfRegisterRequest } from "../login/api";
import { requestAccount } from "./api";
import { FORM_NAME, TOASTR_OPTIONS } from "./constants";
import {
  captchaV3Failure, CLEAR_SELF_REGISTRATION_FAILURE,
  requestAccountFailure, requestAccountRequest, requestAccountSuccess, REQUEST_ACCOUNT_FAILURE, REQUEST_ACCOUNT_REQUEST,
  REQUEST_ACCOUNT_SUCCESS, selfRegistrationFailure, selfRegistrationSuccess, SELF_REGISTRATION_FAILURE,
  SELF_REGISTRATION_SUCCESS
} from "./duck";
import {
  citizenshipSelector,
  emailAddressSelector,
  firstNameSelector,
  hasFailedV3CaptchaSelector,
  isSelfRegistrationFailedSelector,
  lastNameSelector,
  languageSelector,
  reasonSelector,
  registrationReasonSelector,
  specifiedRegistrationReasonSelector
} from "./selectors";

export function* requestAccountSaga(action: ReturnType<typeof requestAccountRequest>) {
  yield put(startSubmit(FORM_NAME));

  const firstName = yield select(firstNameSelector);
  const lastName = yield select(lastNameSelector);
  const emailAddress = yield select(emailAddressSelector);
  const citizenship = yield select(citizenshipSelector);
  const language = yield select(languageSelector);
  const reason = yield select(reasonSelector);
  const registrationReason = yield select(registrationReasonSelector);
  const specifiedRegistrationReason = yield select(specifiedRegistrationReasonSelector);
  const isSelfRegistrationFailed = yield select(isSelfRegistrationFailedSelector);
  const isSelfRegistrationEnabled = clarakmConfig.login.selfRegistration.enabled;
  const canAttemptSelfRegister = isSelfRegistrationEnabled && !isSelfRegistrationFailed;
  const captchaToken = action.token;

  if (canAttemptSelfRegister) {
    yield call(selfRegister, firstName, lastName, emailAddress, citizenship, language, reason, registrationReason, specifiedRegistrationReason, captchaToken);
  }
  else {
    const body = { firstName, lastName, emailAddress, citizenship, language, description: reason, subject: "Account Request", captchaToken };

    yield call(submitAccountRequest, body);
  }
}

function* selfRegister(firstName, lastName, emailAddress, citizenship, language, justification, registrationReason, specifiedRegistrationReason, captchaToken) {
  const { error } = yield call(selfRegisterRequest, firstName, lastName, emailAddress, citizenship, language, justification, registrationReason, specifiedRegistrationReason, captchaToken);

  //  if the email or citizenship is not whitelisted, request an account for them
  if (error?.response?.status === 405 && clarakmConfig.login.allowRequestAccount) {
    const body = { firstName, lastName, emailAddress, citizenship, description: justification, subject: "Account Request", registrationReason, specifiedRegistrationReason, captchaToken };

    yield call(submitAccountRequest, body);
  }
  //  if the email already exists, their password is reset
  else if (error?.response?.status === 409 && clarakmConfig.login.selfRegistration.enabled) {
    yield call(selfRegistrationSuccessSaga);
  }
  // if they fail authentication, provide them manual captcha checkbox
  else if (error?.response?.status === 403) {
    yield put(captchaV3Failure());
  }
  else if (error) {
    yield put(selfRegistrationFailure(error));
  }
  else {
    yield put(selfRegistrationSuccess());
  }
}

function* submitAccountRequest(body) {
  const isV2Captcha = yield select(hasFailedV3CaptchaSelector);
  const { error } = yield call(requestAccount, { ...body, isV2Captcha });

  if (error) {
    if (error?.response?.status === 403) {
      yield put(captchaV3Failure());
    }
    else {
      yield put(requestAccountFailure(error));
    }
  }
  else {
    yield put(requestAccountSuccess());
  }
}

export function* requestAccountFailureSaga(error) {
  yield put(stopSubmit(FORM_NAME));
  yield call(claraToastr.apiError, error);
}

export function* selfRegistrationFailureSaga(error) {
  yield put(stopSubmit(FORM_NAME));
  yield call(claraToastr.apiError, error.error);
}

export function* requestAccountSuccessSaga() {
  yield call(claraToastr.success, "Account Request Sent", "Someone from our staff will reach out to you shortly to send account activation instructions or to request more information, if needed.", TOASTR_OPTIONS);
  yield successSaga();
}

export function* selfRegistrationSuccessSaga() {
  yield call(claraToastr.success, "Account Created", "An email has been sent to you which will allow you to create a password for your account, and login.", TOASTR_OPTIONS);
  yield successSaga();
}

function* successSaga() {
  yield put(clearFields(FORM_NAME, false, false));
  yield put(destroy(FORM_NAME));
  yield put(stopSubmit(FORM_NAME));
  yield put(changeTabToLogin());
}

export function* clearSelfRegistrationSaga() {
  yield call(claraToastr.removeByType, "info");
}

export const sagas = [ takeEvery(REQUEST_ACCOUNT_REQUEST, requestAccountSaga), takeEvery(REQUEST_ACCOUNT_FAILURE, requestAccountFailureSaga), takeEvery(SELF_REGISTRATION_FAILURE, selfRegistrationFailureSaga), takeEvery(SELF_REGISTRATION_SUCCESS, selfRegistrationSuccessSaga), takeEvery(REQUEST_ACCOUNT_SUCCESS, requestAccountSuccessSaga), takeEvery(CLEAR_SELF_REGISTRATION_FAILURE, clearSelfRegistrationSaga) ];
