import { APP_KEY, RAMPART_API } from "../app";
import { getDeviceId } from "../app/storage-util";

export const login = (emailAddress: string, username: string, password: string, twoFactorCode: string, trustDevice: boolean) => {
  const payload = {
    emailAddress,
    username,
    password,
    twoFactorCode,
    appKey: APP_KEY,
    deviceId: getDeviceId(),
    trustDevice,
  };

  return RAMPART_API
    .post("token", payload)
    .then(response => ({ response: response.data }))
    .catch(context => ({ error: context }));
};

export const requestTwoFactorCode = (emailAddress: string, username: string, password: string, captchaToken: string, twoFactorMethod) => {
  const payload = {
    emailAddress,
    username,
    password,
    captchaToken,
    twoFactorMethod,
  };

  return RAMPART_API.post("twofactor", payload).catch(context => ({ error: context }));
};

export const selfRegister = (firstName: string, lastName: string, primaryEmailAddress: string, citizenshipIsoCode2: string, language: string, justification: string, registrationReason: string, specifiedRegistrationReason: string, captchaToken: string) => {
  const payload = {
    firstName,
    lastName,
    primaryEmailAddress,
    citizenshipIsoCode2,
    language,
    justification,
    registrationReason,
    specifiedRegistrationReason,
    captchaToken,
  };

  return RAMPART_API.post("person/self", payload).catch(context => ({ error: context }));
};
