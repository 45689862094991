import React, { ReactNode } from "react";

// TODO this was moved from @teslagov/react-components but we should consider if it's even needed.
export function Errors({ errors, children }: Props) {
  const hasErrors = errors && errors.length > 0;
  const hasChildren = children && children.length > 0;

  if (!hasErrors && !hasChildren) {
    return null;
  }
  else {
    return (
      <div className="form-group row mt-2">
        <div className="col-12">
          <div className="alert alert-danger" role="alert">
            {hasErrors && errors.map((error, i) => (
              <div key={i} className="error-message">
                {error.message}
              </div>
            ))}

            {hasChildren && children}
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  errors?: { message: string }[];
  children?: ReactNode[];
};
