import "./styles.scss";
import { BannerMode, clarakmConfig } from "@teslagov/clarakm-env-js";
import React, { PureComponent } from "react";
import { DEFAULT_MESSAGES } from "./MaintenanceContainer";

class MaintenanceBanner extends PureComponent<Record<string, unknown>, Record<string, unknown>> {
  render() {
    return (
      <div className="row">
        <div className="col-sm-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
          <div className="alert alert-warning alert-maintenance">{this.getMessage()}</div>
        </div>
      </div>
    );
  }

  getMessage() {
    const maintenanceConfig = clarakmConfig.app.maintenance;

    let messages = DEFAULT_MESSAGES;

    if (maintenanceConfig.messages && maintenanceConfig.messages.length > 0) {
      if (maintenanceConfig.bannerMode === BannerMode.AllMessages) {
        messages = maintenanceConfig.messages;
      }
      else if (maintenanceConfig.bannerMode === BannerMode.FirstMessageOnly) {
        messages = [ maintenanceConfig.messages[0] ];
      }
    }

    return messages.map((o, i) => <p key={`maintenance-message_${i}`}>{o}</p>);
  }
}

export default MaintenanceBanner;
