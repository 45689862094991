import * as ActionTypes from "./action-types";
import * as loginActions from "./actions";
import { ActionType } from "typesafe-actions";

export type ReducerState = {
  loading: boolean;
  isLoggedIn: boolean;
  twoFactorRequired: false;
  twoFactorMethods: any[];
  twoFactorCodeSent: boolean;
  passwordExpired: boolean;
};

const initialState: ReducerState = {
  loading: false,
  isLoggedIn: false,
  twoFactorRequired: false,
  twoFactorMethods: [],
  twoFactorCodeSent: false,
  passwordExpired: false
};

type LoginAction = ActionType<typeof loginActions>;

export default function auth(state = initialState, action: LoginAction): ReducerState {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.TWO_FACTOR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        twoFactorRequired: false,
        twoFactorCodeSent: false,
      };

    case ActionTypes.TWO_FACTOR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        twoFactorCodeSent: true,
      };

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        passwordExpired: action.payload.passwordExpired,
        twoFactorRequired: action.payload.twoFactorRequired,
        twoFactorMethods: action.payload.twoFactorMethods,
      };

    case ActionTypes.TWO_FACTOR_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        twoFactorCodeSent: false,
      };

    case ActionTypes.RESET_TWO_FACTOR_REQUEST:
      return {
        ...state,
        twoFactorRequired: false,
        twoFactorCodeSent: false,
      };

    case ActionTypes.RESET_PASSWORD_EXPIRED:
      return {
        ...state,
        passwordExpired: false,
      };

    default:
      return state;
  }
}
