import { Title } from "@teslagov/clarakm-js-react";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { CAPTCHA_ACTIONS } from "../../app/constants";
import { executeCallbackWithCaptchaIfNecessary } from "../../app/utils";
import HelpForm from "../components/HelpForm";
import { helpSubmissionRequest, hideModal } from "../duck";
import { captchaV2TokenSelector, hasFailedV3CaptchaSelector, isModalShowingSelector } from "../selectors";

class HelpContainer extends Component<Props, State> {
  render() {
    const { isModalOpen } = this.props;
    const { hideModal } = this.props.actions;

    return (
      <div className="card mx-auto p-4">
        <Title value={t("help.title")} />
        <div className="card-block">
          <Modal isOpen={isModalOpen} toggle={hideModal} ariaHideApp={false}>
            <ModalHeader>Help Request Sent</ModalHeader>
            <ModalBody>
              <p>Thanks for reaching out, someone from our staff will be in touch shortly to provide assistance.</p>
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-primary" onClick={() => {
                hideModal(); this.props.history.push("/login");
              }}>
                Close
              </button>
            </ModalFooter>
          </Modal>
          <HelpForm onSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }

  handleSubmit = () => {
    const { actions: { onSubmit }, hasFailedV3Captcha, captchaV2Token } = this.props;

    return executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.helpRequest, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };
}

type Props = InjectedProps & InjectedActions & { history: any };

type State = Record<string, unknown>;

type InjectedProps = {
  isModalOpen: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

type InjectedActions = {
  actions: {
    onSubmit: typeof helpSubmissionRequest;
    hideModal: typeof hideModal;
  };
};

const mapStateToProps = createStructuredSelector({
  isModalOpen: isModalShowingSelector,
  hasFailedV3Captcha: hasFailedV3CaptchaSelector,
  captchaV2Token: captchaV2TokenSelector,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onSubmit: helpSubmissionRequest,
      hideModal,
    },
    dispatch
  )
});

export default connect<InjectedProps, InjectedActions>(
  mapStateToProps,
  mapDispatchToProps
)(HelpContainer);
