import * as ActionType from "./action-types";
import { action } from "typesafe-actions";

export const loginRequest = (email, username, password, twoFactorCode, trustDevice, redirectTo) => action(ActionType.LOGIN_REQUEST, { email, username, password, twoFactorCode, trustDevice, redirectTo } );
export const loginSuccess = token => action(ActionType.LOGIN_SUCCESS, token);
export const loginFailure = payload => action(ActionType.LOGIN_FAILURE, payload);

export const sendTwoFactorCodeRequest = (email, username, password, captchaToken, method, toggleCaptchaFailure) => action(ActionType.TWO_FACTOR_CODE_REQUEST, { email, username, password, captchaToken, method, toggleCaptchaFailure } );
export const sendTwoFactorCodeSuccess = () => action(ActionType.TWO_FACTOR_CODE_SUCCESS);
export const sendTwoFactorCodeFailure = () => action(ActionType.TWO_FACTOR_CODE_FAILURE);
export const resetTwoFactorRequest = () => action(ActionType.RESET_TWO_FACTOR_REQUEST);
export const resetPasswordExpired = () => action(ActionType.RESET_PASSWORD_EXPIRED);
