import { NAME, FORM_NAMES, FORM_FIELD_NAMES } from "./constants";
import { formValueSelector } from "redux-form";
import { ReducerState } from "./duck";

const reducerSelector = (state): ReducerState => state[NAME];

const emailFormSelector = formValueSelector(FORM_NAMES.passwordResetEmailForm);
const newPasswordFormValueSelector = formValueSelector(FORM_NAMES.passwordResetNewPasswordForm);

export const errorsSelector = state => reducerSelector(state).errors;
export const loadingSelector = state => reducerSelector(state).loading;
export const isModalShowingSelector = state => reducerSelector(state).isModalShowing;
export const hasFailedV3CaptchaSelector = state => reducerSelector(state).hasFailedV3Captcha;
export const captchaV2TokenSelector = state => reducerSelector(state).captchaV2Token;

export const emailAddressSelector = state => emailFormSelector(state, FORM_FIELD_NAMES.emailAddress);

export const resetEmailAddressSelector = state => newPasswordFormValueSelector(state, FORM_FIELD_NAMES.emailAddress);
export const codeSelector = state => newPasswordFormValueSelector(state, FORM_FIELD_NAMES.code);
export const resetTokenSelector = state => newPasswordFormValueSelector(state, FORM_FIELD_NAMES.resetToken);
export const newPasswordSelector = state => newPasswordFormValueSelector(state, FORM_FIELD_NAMES.newPassword);
export const newPasswordConfirmSelector = state => newPasswordFormValueSelector(state, FORM_FIELD_NAMES.confirmNewPassword);
