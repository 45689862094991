import { Errors } from "../../app/components/Errors";
import React, { Component } from "react";
import { connect } from "react-redux";
import Title from "../../app/components/Title";
import * as Actions from "../duck/actions";
import { errorsSelector, isLoggedInSelector, twoFactorCodeSentSelector, twoFactorRequiredSelector, passwordExpiredSelector } from "../duck/selectors";
import LoginForm from "./LoginForm";
import TwoFactorRequestForm from "./TwoFactorRequestForm";
import TwoFactorResponseForm from "./TwoFactorResponseForm";
import {executeCallbackWithCaptchaIfNecessary} from "../../app/utils";
import {CAPTCHA_ACTIONS, RAMPART_API} from "../../app/constants";
import ExpiredPasswordModal from "./ExpiredPasswordModal";
import { getHistory } from "../../app";
import {resetPasswordExpired} from "../duck/actions";
import { t } from "i18next";

class LoginContainer extends Component<Props, State> {
  state: State = {
    email: null,
    password: null,
    username: null,
    passwordExpired: false,
    hasFailedV3Captcha: false,
    captchaV2Token: undefined,
    providers: [],
  };

  componentDidMount() {
    RAMPART_API
      .get("/oidc/provider")
      .then(r => this.setState({ providers: r.data }))
      .catch(() => this.setState({ providers: [] }));
  }

  handleLoginSubmit = values => {
    const { redirectTo, login } = this.props;
    const { email, username, password } = values;

    this.setState({ email, username, password });
    resetPasswordExpired();
    login(email, username, password, undefined, undefined, redirectTo);
  };

  handleTwoFactorRequestSubmit = values => {
    const { email, username, password, captchaV2Token, hasFailedV3Captcha } = this.state;
    const { sendTwoFactorCode } = this.props;
    const { method } = values;
    const onSubmit = (token: string) => sendTwoFactorCode(email, username, password, token, method, () => this.setState({hasFailedV3Captcha: true}));

    executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.twoFactor, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };

  handleTwoFactorResponseSubmit = values => {
    const { redirectTo, login } = this.props;
    const { email, username, password } = this.state;
    const { code, trustDevice } = values;

    login(email, username, password, code, trustDevice, redirectTo);
  };

  togglePasswordExpiredModal = () => {
    this.setState({
      passwordExpired: !this.props.passwordExpired
    }, () => {
      const history = getHistory();
      history.push("/password-confirm");
    });
  };

  render() {
    const { errors, twoFactorRequired, isLoggedIn, passwordExpired, handleLanguageChange } = this.props;

    return (
      <div className="login-container">
        <Title />

        {errors && !isLoggedIn && <Errors errors={errors} />}

        {!twoFactorRequired && <LoginForm onSubmit={this.handleLoginSubmit} providers={this.state.providers} handleLanguageChange={handleLanguageChange}/>}

        {twoFactorRequired && !isLoggedIn && this.renderTwoFactor()}

        {isLoggedIn && <div className="text-center text-dark">{t("signIn.messages.signedIn")}</div>}

        <ExpiredPasswordModal toggle={this.togglePasswordExpiredModal} isOpen={passwordExpired} />
      </div>
    );
  }

  renderTwoFactor(): React.ReactNode {
    const {hasFailedV3Captcha, captchaV2Token} = this.state;
    const { twoFactorCodeSent } = this.props;

    if (!twoFactorCodeSent) {
      return (
        <TwoFactorRequestForm
          onSubmit={this.handleTwoFactorRequestSubmit}
          hasFailedV3Captcha={hasFailedV3Captcha}
          captchaV2Token={captchaV2Token}
          storeCaptchaV2Token={(captchaV2Token: string) => this.setState({captchaV2Token})}
        />
      );
    }
    else {
      return (
        <TwoFactorResponseForm
          onSubmit={this.handleTwoFactorResponseSubmit}
          hasFailedV3Captcha={hasFailedV3Captcha}
          captchaV2Token={captchaV2Token}
          storeCaptchaV2Token={(captchaV2Token: string) => this.setState({captchaV2Token})}
        />
      );
    }
  }
}

export type ProviderResource = {
  displayName: string;
  authorizationUrl: string;
  key: string;
}

type Props = MyProps & InjectedProps & InjectedActions;

type MyProps = {
  redirectTo: any;
  handleLanguageChange: any;
};

type InjectedProps = {
  errors: any;
  isLoggedIn: boolean;
  passwordExpired: boolean;
  twoFactorRequired: boolean;
  twoFactorCodeSent: boolean;
};

type InjectedActions = {
  login: typeof Actions.loginRequest;
  sendTwoFactorCode: typeof Actions.sendTwoFactorCodeRequest;
  resetPasswordExpired: typeof Actions.resetPasswordExpired;
};

type State = {
  email: string;
  password: string;
  username: string;
  passwordExpired: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
  providers: ProviderResource[];
  isOidcCallBack?: boolean;
};

const mapStateToProps = state => ({
  errors: errorsSelector(state),
  isLoggedIn: isLoggedInSelector(state),
  passwordExpired: passwordExpiredSelector(state),
  twoFactorRequired: twoFactorRequiredSelector(state),
  twoFactorCodeSent: twoFactorCodeSentSelector(state),
});

const mapDispatchToProps = {
  login: Actions.loginRequest,
  sendTwoFactorCode: Actions.sendTwoFactorCodeRequest,
  resetPasswordExpired: Actions.resetPasswordExpired
};

export default connect<InjectedProps, InjectedActions, MyProps>(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
