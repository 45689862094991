export const NAME = "password-reset";

export const FORM_NAMES = {
  passwordResetEmailForm: "passwordResetEmailForm",
  passwordResetCodeForm: "passwordResetCodeForm",
  passwordResetNewPasswordForm: "passwordResetNewPasswordConfirmationForm",
};

export const FORM_FIELD_NAMES = {
  emailAddress: "emailAddress",
  code: "code",
  resetToken: "resetToken",
  newPassword: "newPassword",
  confirmNewPassword: "confirmPassword",
};
