import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { DefaultLanguageCode, LanguageDropdown, getLanguageFromLocalStorage, i18n, setLanguageInLocalStorage } from "@teslagov/clarakm-js-react";
import { t } from "i18next";
import React, { Component } from "react";
import Title from "../app/components/Title";
import "./styles.scss";

class Disclaimer extends Component<Props> {
  state: State = {
    language: getLanguageFromLocalStorage() ?? DefaultLanguageCode,
  };

  render() {
    const { language } = this.state;
    const { onAgreed } = this.props;

    return (
      <div className="card disclaimer mx-auto p-4">
        <Title />

        <div className="content" dangerouslySetInnerHTML={{ __html: t("disclaimer.text") }} />

        <div className="mt-3 row">
          <div className="col-12 col-md-4 d-flex align-items-center">
            {clarakmConfig.app.translation.enabled && (
              <LanguageDropdown
                language={language}
                options={clarakmConfig.app.translation.supportedLocales}
                onChange={selectedLanguage => {
                  i18n.changeLanguage(selectedLanguage).then(() => {
                    setLanguageInLocalStorage(selectedLanguage);
                    this.setState({ language: selectedLanguage });
                  });
                }}
              />
            )}
          </div>

          <div className="col-12 col-md-4 text-center">
            <button className="btn btn-primary" onClick={onAgreed}>
              {t("disclaimer.actions.accept")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

type State = {
  language?: string;
};

type Props = {
  onAgreed: () => void;
};

export default Disclaimer;
