import {v4 as uuidGen} from "uuid";
import {ProviderResource} from "../login/containers/LoginContainer";
import {RAMPART_API} from "./constants";
import {setOIDCRequestId} from "./storage-util";
import { parse } from "query-string";

export function generateStateForProvider(provider): OIDCState {
  const params: any = parse(window.location.search);
  const redirect: string = params.return_url;

  return {
    provider,
    redirect,
    requestId: uuidGen()
  };
}

export function doAuthorizationRedirect(provider: ProviderResource) {
  const oidcState = generateStateForProvider(provider.key);
  const base64EncodedState = btoa(JSON.stringify(oidcState));

  setOIDCRequestId(oidcState.requestId);
  window.location.href = `${provider.authorizationUrl}&state=${base64EncodedState}&nonce=${uuidGen()}`;
}

export function getProviderForIdPRequestIssuer(issuer: string): Promise<ProviderResource> {
  return RAMPART_API
    .get("/oidc/provider")
    .then(response => {
      const issuerUrl = new URL(issuer);
      const providers: ProviderResource[] = response.data;

      return providers.find(provider => {
        const providerUrl = new URL(provider.authorizationUrl);
        return providerUrl.hostname === issuerUrl.hostname;
      });
    });
}

export type OIDCState = {
  provider: string,
  requestId: string
  redirect: string
}
