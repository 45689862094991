import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { clarakmConfig } from "@teslagov/clarakm-env-js";

class ExpiredPasswordModal extends Component<Props> {
  render() {
    const { toggle, isOpen } = this.props;

    return (
      <Modal className="expired-password-modal" size="lg" {...{ isOpen, toggle }} backdrop="static">
        <ModalHeader toggle={toggle}>
          Password Expired
        </ModalHeader>
        <ModalBody>
          <p>
            Your password has expired. We have sent you an email with instructions regarding establishing a new password.
            If you have any questions, please <a href={`mailto:${clarakmConfig.app.helpEmail}`}>contact us</a> for assistance. We will respond to your request as soon as possible.
          </p>
        </ModalBody>
        <ModalFooter>
          <button id="expired-password-modal-button" className="btn btn-primary" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

type Props = {
  toggle: () => void;
  isOpen: boolean;
};

export default ExpiredPasswordModal;
