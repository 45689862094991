import { NAME, FORM_NAME, FORM_FIELD_NAMES } from "./constants";
import { formValueSelector } from "redux-form";
import { ReducerState } from "./duck";

export const reducerStateSelector = (state): ReducerState => state[NAME];

export const errorsSelector = state => reducerStateSelector(state).errors;
export const loadingSelector = state => reducerStateSelector(state).loading;
export const isModalShowingSelector = state => reducerStateSelector(state).isModalShowing;
export const isSelfRegistrationFailedSelector = state => reducerStateSelector(state).isSelfRegistrationFailed;
export const hasFailedV3CaptchaSelector = state => reducerStateSelector(state).hasFailedV3Captcha;
export const captchaV2TokenSelector = state => reducerStateSelector(state).captchaV2Token;

export const formSelector = formValueSelector(FORM_NAME);
export const firstNameSelector = state => formSelector(state, FORM_FIELD_NAMES.firstName);
export const lastNameSelector = state => formSelector(state, FORM_FIELD_NAMES.lastName);
export const emailAddressSelector = state => formSelector(state, FORM_FIELD_NAMES.emailAddress);
export const citizenshipSelector = state => formSelector(state, FORM_FIELD_NAMES.citizenship);
export const languageSelector = state => formSelector(state, FORM_FIELD_NAMES.language);
export const reasonSelector = state => formSelector(state, FORM_FIELD_NAMES.reason);
export const registrationReasonSelector = state => formSelector(state, FORM_FIELD_NAMES.registrationReason);
export const specifiedRegistrationReasonSelector = state => formSelector(state, FORM_FIELD_NAMES.specifiedRegistrationReason);
