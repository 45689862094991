import { publicClarakmConfig as clarakmConfig } from "@teslagov/clarakm-env-js";
import React, { SyntheticEvent } from "react";
import defaultLogo from "./logo.svg";

const Title: React.FunctionComponent<Record<string, unknown>> = () => (
  <h4 className="card-title d-flex my-2">
    <img src={`${clarakmConfig.icons.baseUrl}/logo-login.svg`} onError={useDefaultLogo} className="login-logo mx-auto my-3" alt="logo" />
  </h4>
);

function useDefaultLogo(e: SyntheticEvent<HTMLImageElement, Event>) {
  if (e.currentTarget.src !== defaultLogo) {
    e.currentTarget.src = defaultLogo;
  }
}

export default Title;
