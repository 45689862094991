import React from "react";
import "./styles.scss";

const ErrorsAndWarnings = ({ touched, error, warning, label }) => {
  const errorLabel = label === "How can we help?" ? "issue" : label;
  const errorMessage = error?.message === "This is a required field." ? `Please enter a ${errorLabel.toLowerCase()}.` : error;

  return <div className="mb-3">{(touched && errorMessage && <span className="text-danger">{errorMessage}</span>) || (touched && warning && <span className="clarakm-form-warning">{warning}</span>)}</div>;
};

export default ErrorsAndWarnings;
