import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";
import React, { Component } from "react";
import {storeCaptchaV2Token, clearSelfRegistrationFailure, hideModal, requestAccountRequest} from "../duck";
import RequestAccountForm from "../components/RequestAccountForm";
import {captchaV2TokenSelector, hasFailedV3CaptchaSelector, isModalShowingSelector} from "../selectors";
import {executeCallbackWithCaptchaIfNecessary} from "../../app/utils";
import {CAPTCHA_ACTIONS} from "../../app/constants";

class RequestAccountContainer extends Component<Props, Record<string, unknown>> {
  render() {
    const { onSubmit, goBack, storeCaptchaV2Token } = this.props.actions;

    return <RequestAccountForm onSubmit={onSubmit} goBack={goBack} storeCaptcha={storeCaptchaV2Token} />;
  }

  handleSubmit = e => {
    const { actions: {onSubmit}, captchaV2Token, hasFailedV3Captcha } = this.props;

    e.preventDefault();

    return executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.helpRequest, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };
}

type Props = MyProps & InjectedProps & InjectedActions;

type MyProps = {
  onClose: () => void;
};

type InjectedProps = {
  isModalOpen: boolean;
  captchaV2Token: string;
  hasFailedV3Captcha: boolean;
};

type InjectedActions = {
  actions: {
    onSubmit: (captcha: string) => void;
    goBack: () => void;
    hideModal: () => void;
    storeCaptchaV2Token: (token: string) => void;
  };
};

const mapStateToProps = createStructuredSelector({
  isModalOpen: isModalShowingSelector,
  captchaV2Token: captchaV2TokenSelector,
  hasFailedV3Captcha: hasFailedV3CaptchaSelector,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onSubmit: requestAccountRequest,
      goBack: clearSelfRegistrationFailure,
      hideModal,
      storeCaptchaV2Token: storeCaptchaV2Token,
    },
    dispatch
  ),
});

export default connect<InjectedProps, InjectedActions>(
  mapStateToProps,
  mapDispatchToProps
)(RequestAccountContainer);
