import { appendQuerystring } from "@teslagov/clarakm-js-api";
import { RAMPART_API } from "../app";

class _Api {
  public sendForgotUsernameEmail(email: string, captchaToken, isV2Captcha): Promise<any> {
    const endpoint = appendQuerystring("person/forgot-username", {
      email,
      captchaToken,
      isV2Captcha
    });

    return RAMPART_API.put(endpoint);
  }
}

export const Api = new _Api();
