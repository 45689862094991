import PropTypes from "prop-types";
import { Component } from "react";
import { withRouter } from "react-router";
import "../../styles/main.scss";

class AppContainer extends Component {
  render() {
    return this.props.children;
  }
}

AppContainer.propTypes = {
  // Injected by React Router
  children: PropTypes.node,
};

export default withRouter(AppContainer);
