import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { setTitle } from "@teslagov/clarakm-js-react";
import { t } from "i18next";
import { parse } from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { RAMPART_API } from "../app";
import BrowserSupportWrapper from "../app/components/BrowserSupportWrapper";
import Title from "../app/components/Title";

class AuthorizeContainer extends Component<Props, State> {
  state = {
    tokenInfo: null,
    submitting: false,
    authorized: false,
    errorMessage: null,
  };

  componentDidMount() {
    this.getTokenInfo();
    setTitle("Authorize");
  }

  componentDidUpdate(prevProps: Props) {
    const token = parse(this.props.location.search).token;
    const prevToken = parse(prevProps.location.search).token;

    if (token !== prevToken) {
      this.getTokenInfo();
    }
  }

  getTokenInfo() {
    const token = this.getEncodedToken();

    return RAMPART_API.get(`/authorized-application/token/${token}/info`)
      .then(r =>
        this.setState({
          tokenInfo: r.data,
        })
      )
      .catch(this.handleErrorResponse);
  }

  authorizeApp = () => {
    const token = this.getEncodedToken();

    this.setState({ submitting: true });

    return RAMPART_API.post(`/authorized-application/token/${token}`)
      .then(r => this.setState({ authorized: true }))
      .catch(this.handleErrorResponse)
      .finally(() => this.setState({ submitting: false }));
  };

  handleErrorResponse = (errorResponse: any) => {
    const response = errorResponse.response;

    // in case Rampart doesn't respond, redirect to login
    if (!response || !response.data || response.status === 401) {
      const token = this.getToken();
      const redirectParam = getLoginRedirectUrl(token);

      this.props.push(`/login?redirect=${redirectParam}`);
    }
    else {
      const error = response.data.errors[ 0 ];
      const token = this.getEncodedToken();
      const errorRenderer = errorMap[ error.code ];
      let errorMessage;

      // if no renderer exists for the error code, just display the raw message / code
      if (!errorRenderer) {
        errorMessage = (
          <p className="text-center font-weight-bold">
            {error.message} ({error.code})
          </p>
        );
      }
      // otherwise, use the error renderer
      else {
        errorMessage = errorRenderer(token);
      }

      this.setState({ errorMessage });
    }
  };

  getToken() {
    return parse(this.props.location.search).token as string;
  }

  getEncodedToken() {
    const token = this.getToken();

    return encodeURIComponent(token);
  }

  render() {
    const { tokenInfo, errorMessage } = this.state;
    let component;

    if (errorMessage != null) {
      component = (
        <div>
          {errorMessage || (
            <p className="text-center">
              An error occurred while accessing the authorization information for this application.
              <br />
            </p>
          )}
        </div>
      );
    }
    else if (tokenInfo == null) {
      component = <p className="text-center">Please wait while we access the authorization information for this application.</p>;
    }
    else {
      const { authorized } = this.state;
      const { appName } = tokenInfo;

      if (authorized) {
        component = (
          <React.Fragment>
            <p className="text-center font-weight-bold">{appName} has been authorized.</p>
            <p>{appName} has been authorized. To continue, please return to the application and sign in again.</p>
          </React.Fragment>
        );
      }
      else {
        const {
          tokenInfo: { ipAddress },
          submitting,
        } = this.state;

        component = (
          <React.Fragment>
            <p className="text-center font-weight-bold">Authorize {appName} application?</p>
            <p>
              The application "{appName}" is attempting to authenticate to your {clarakmConfig.app.title} account{ipAddress && ` from the IP address ${ipAddress}`}.
            </p>
            <p>Would you like to authorize this application?</p>

            <button type="button" disabled={submitting} className="btn btn-primary" onClick={this.authorizeApp}>
              <span className="mx-auto">Yes, authorize this application.</span>
            </button>
          </React.Fragment>
        );
      }
    }

    return (
      <BrowserSupportWrapper>
        <div className="card mx-auto">
          <div className="card-block px-5">
            <Title />

            {component}

            <div className="d-flex justify-content-around mt-4">
              <Link to={"/"} className="btn btn-sm btn-link">
                {t("authorization.actions.returnToLogin")}
              </Link>
              <Link to={"/help"} className="btn btn-sm btn-link">
                {t("actions.needHelp")}
              </Link>
            </div>
          </div>
        </div>
      </BrowserSupportWrapper>
    );
  }
}

const errorMap: {
  [ key: number ]: (token: string) => React.ReactNode;
} = {
  13002: token => (
    <React.Fragment>
      <p className="text-center font-weight-bold">The authorization token has expired.</p>
      <p>Please login via the application again, which will generate a new authorization email. Click the link with the new email in order to authorize the application.</p>
    </React.Fragment>
  ),
  13003: token => (
    <React.Fragment>
      <p className="text-center font-weight-bold">The application is already authorized.</p>
      <p>You should be able to login via the application now.</p>
    </React.Fragment>
  ),
  13004: token => {
    const redirectParam = getLoginRedirectUrl(token);

    return (
      <React.Fragment>
        <p className="text-center font-weight-bold">Logged in to wrong account.</p>
        <p>
          You appear to be logged in to a different account. In order to authorize this application, please <Link to={`/login?redirect=${redirectParam}`}>login using the same account</Link> you used to login to the application.
        </p>
      </React.Fragment>
    );
  },
};

function getLoginRedirectUrl(token: string): string {
  return encodeURIComponent(`/login/authorize?token=${token}`);
}

type TokenInfo = {
  appName: string;
  ipAddress: string;
};

type InjectedActions = {
  push: typeof push;
};

type Props = RouteChildrenProps & InjectedActions;

type State = {
  tokenInfo: TokenInfo;
  submitting: boolean;
  authorized: boolean;
  errorMessage: React.ReactNode;
};

const mapDispatchToProps: InjectedActions = {
  push,
};

export default connect(
  null,
  mapDispatchToProps
)(AuthorizeContainer);
