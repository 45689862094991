import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

const HIDE_WARNING_KEY = "hide-browser-warning";
const HIDE_WARNING_EXPIRATION_KEY = "hide-browser-warning-expiration";
const HIDE_WARNING_EXPIRATION_MS = 14 * 24 * 60 * 1000; // 14 days
const Modernizr: ModernizrStatic = (window as any).Modernizr;

export default class BrowserSupportWrapper extends Component<Record<string, unknown>, State> {
  state: State = {
    hideWarning: false,
    dismissed: false,
  };

  componentDidMount() {
    const hideWarning = !!localStorage.getItem(HIDE_WARNING_KEY);

    if (hideWarning) {
      const now = Date.now();
      const hideWarningExpiration = localStorage.getItem(HIDE_WARNING_EXPIRATION_KEY);
      const hideWarningExpirationEpoch = hideWarningExpiration ? Number(hideWarningExpiration) : null;

      if (hideWarningExpirationEpoch != null && now <= hideWarningExpirationEpoch) {
        this.setState({
          dismissed: true,
        });
      }
    }
  }

  toggleHideWarning = () =>
    this.setState(prevState => ({
      hideWarning: !prevState.hideWarning,
    }));

  dismissWarning = () => {
    const { hideWarning } = this.state;

    if (hideWarning) {
      const expiration = Date.now() + HIDE_WARNING_EXPIRATION_MS;

      localStorage.setItem(HIDE_WARNING_KEY, true.toString());
      localStorage.setItem(HIDE_WARNING_EXPIRATION_KEY, expiration.toString());
    }
    else {
      localStorage.removeItem(HIDE_WARNING_KEY);
      localStorage.removeItem(HIDE_WARNING_EXPIRATION_KEY);
    }

    this.setState({
      dismissed: true,
    });
  };

  render() {
    const { children } = this.props;
    const { dismissed } = this.state;

    if (dismissed) {
      return children;
    }
    else {
      const es5 = Modernizr.es5 && Modernizr.json && Modernizr.queryselector;
      const css = Modernizr.flexbox;
      const cookies = Modernizr.cookies;
      const localStorage = Modernizr.localstorage;
      const file = Modernizr.filereader && Modernizr.fileinput;
      const svg = Modernizr.svg;
      const supportsEverything = es5 && css && cookies && localStorage && file && svg;

      if (supportsEverything) {
        return children;
      }
      else {
        return (
          <div className="row mt-5">
            <div className="col col-xs-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4">
              <div className="card card-wide bg-light">
                <div className="card-body">
                  <h5 className="card-title text-danger">
                    <span>Your browser may not be completely supported!</span>
                  </h5>

                  <div className="text-muted">
                    <p>We've detected that your browser may not meet all of the requirements to use this application. You may continue, but your experience may be less than ideal.</p>

                    {!es5 && <BrowserSupportBlock title="JavaScript / ECMAScript 5 (ES5)" text="This application uses JavaScript features which your browser may not support at all, may support partially, or may support incorrectly." moreInfoLink="https://caniuse.com/#feat=es5" />}
                    {!css && <BrowserSupportBlock title="Cascading Style Sheets (CSS)" text="This application uses CSS features which your browser may not support at all, may support partially, or may support incorrectly. This may cause visual issues." moreInfoLink="https://caniuse.com/#feat=flexbox" />}
                    {!cookies && <BrowserSupportBlock title="Cookies" text="This application uses cookies to secure your session." moreInfoLink="https://www.whatismybrowser.com/guides/how-to-enable-cookies" />}
                    {!localStorage && <BrowserSupportBlock title="Local Storage" text="This application uses a small amount of in-browser storage." moreInfoLink="https://caniuse.com/#feat=namevalue-storage" />}
                    {!file && <BrowserSupportBlock title="File Uploads" text="File uploads may not work in your browser." moreInfoLink="https://caniuse.com/#feat=fileapi" />}
                    {!svg && <BrowserSupportBlock title="Scalable Vector Graphics (SVG)" text="Some visual resources in this application are SVGs. Your browser does not appear to support this technology, so some icons and images may not display correctly." moreInfoLink="https://caniuse.com/#feat=svg" />}
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-check mt-2">
                        <input id="hideCheckbox" type="checkbox" className="form-check-input" onClick={this.toggleHideWarning} />
                        <label htmlFor="hideCheckbox" className="form-check-label">
                          Hide this warning for a while
                        </label>
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <button type="button" onClick={this.dismissWarning} className="btn btn-primary">
                        <FA icon={faChevronRight} fixedWidth />
                        Proceed to Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const BrowserSupportBlock = (props: { title: string; text: string; moreInfoLink?: string }) => {
  const { title, text, moreInfoLink } = props;

  return (
    <React.Fragment>
      <div className="font-weight-bold">{title}</div>
      <p>
        {text}
        {moreInfoLink && (
          <small>
            {" "}
            (
            <a href={moreInfoLink} target="_blank" rel="noopener noreferrer">
              more info
            </a>
            )
          </small>
        )}
      </p>
    </React.Fragment>
  );
};

type State = {
  hideWarning: boolean;
  dismissed: boolean;
};
