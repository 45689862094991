import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { LogoutReason } from "@teslagov/clarakm-js-api";
import {
  clearSessionStorage,
  executeSsoLoginEndpoints,
  profileActions,
  profileActionTypes,
  removeLogoutReason,
  setTokenExpirationTime,
  setTokenRefreshTime,
  showApiError
} from "@teslagov/clarakm-js-react";
import jwt_decode from "jwt-decode";
import { startSubmit, stopSubmit } from "redux-form";
import { call, put, select, take, takeEvery } from "redux-saga/effects";
import { captchaInfoToastr, claraToastr } from "../../app/constants";
import * as LoginApi from "../api";
import { getRedirectUrl, LOGIN_FORM_NAME, TWO_FACTOR_REQUEST_FORM_NAME, TWO_FACTOR_RESPONSE_FORM_NAME } from "../constants";
import * as ActionTypes from "./action-types";
import * as Actions from "./actions";
import { twoFactorRequiredSelector } from "./selectors";

function* login(action: ReturnType<typeof Actions.loginRequest>) {
  yield put(startSubmit(LOGIN_FORM_NAME));
  yield put(startSubmit(TWO_FACTOR_RESPONSE_FORM_NAME));
  yield call(clearSessionStorage);

  const { email, username, password, twoFactorCode, trustDevice, redirectTo } = action.payload;
  const { response, error } = yield call(LoginApi.login, email, username, password, twoFactorCode, trustDevice);

  if (error) {
    if (error.response === undefined) {
      claraToastr.error("Network Error!", "");
      yield put(stopSubmit(LOGIN_FORM_NAME));
      yield put(startSubmit(TWO_FACTOR_RESPONSE_FORM_NAME));
      return;
    }

    const wasTwoFactorRequired = yield select(twoFactorRequiredSelector);
    const twoFactorRequired = error.response.status === 511;

    if (wasTwoFactorRequired && twoFactorRequired) {
      claraToastr.error("Your access code has expired, please login again.", "");
      yield put(Actions.resetTwoFactorRequest());
    }
    else if (error.response?.data?.errors?.[ 0 ]?.code === 1003) {
      yield put(Actions.loginFailure({ passwordExpired: true }));
    }
    else {
      // only show message if not requesting 2-factor auth
      if (!twoFactorRequired) {
        showApiError(error);
      }

      yield put(Actions.loginFailure({ twoFactorRequired, twoFactorMethods: error.response.data.methods }));
    }
  }
  else {
    const nextUrl = getRedirectUrl(redirectTo);
    const decodedJwt = jwt_decode<any>(response.jwt);

    yield injectSecureEnv();
    yield executeSsoLoginEndpoints();

    setTokenExpirationTime(decodedJwt.expMax);

    if (decodedJwt.exp !== decodedJwt.expMax) {
      setTokenRefreshTime(decodedJwt.exp);
    }

    removeLogoutReason();

    // Wait for the get self request to complete.
    if (clarakmConfig.ups.enabled) {
      yield put(profileActions.getSelfRequest());
      yield take(profileActionTypes.GET_SELF_SUCCESS);
    }

    yield call(() => (window.location.href = nextUrl));
    yield put(Actions.loginSuccess(response));
  }

  yield put(stopSubmit(LOGIN_FORM_NAME));
  yield put(stopSubmit(TWO_FACTOR_RESPONSE_FORM_NAME));
}

export function injectSecureEnv(): Promise<void> {
  return new Promise<void>(resolve => {
    const script = document.createElement("script");

    script.src = "/config/env-secure.js";
    script.onload = () => resolve();

    document.body.appendChild(script);
  });
}

function* sendTwoFactorCode(action: ReturnType<typeof Actions.sendTwoFactorCodeRequest>) {
  yield put(startSubmit(TWO_FACTOR_REQUEST_FORM_NAME));
  const { email, username, password, captchaToken, method, toggleCaptchaFailure } = action.payload;
  const { error } = yield call(LoginApi.requestTwoFactorCode, email, username, password, captchaToken, method);

  if (error) {
    if (error?.response?.status === 403) {
      captchaInfoToastr();
      toggleCaptchaFailure();
    }
    else {
      yield put(Actions.sendTwoFactorCodeFailure());
      yield call(showApiError, error);
    }
  }
  else {
    yield put(Actions.sendTwoFactorCodeSuccess());
  }

  yield put(stopSubmit(TWO_FACTOR_REQUEST_FORM_NAME));
}

function* logoutSuccessSaga(action: ReturnType<typeof profileActions.logoutSuccess>) {
  const reason = action.payload.reason;

  if (reason === LogoutReason.TokenExpired) {
    yield call(claraToastr.info, "Logged Out", "You have been logged out because your session has expired. Please login to continue.", { timeOut: 0 });
  }
  else if (reason === LogoutReason.UserLoggedOut) {
    yield call(claraToastr.info, "Logged Out", "You have been logged out.", { timeOut: 10000 });
  }
  else if (reason === LogoutReason.EmailAddressChanged) {
    if (clarakmConfig.login.allowLoginWithEmail) {
      yield call(claraToastr.info, "Logged Out", "You have been logged out because you changed your email address. Please login with your new email address and existing password to continue.", { timeOut: 10000 });
    }
    else {
      yield call(claraToastr.info, "Logged Out", "You have been logged out because you changed your email address. Please login again to continue.", { timeOut: 10000 });
    }
  }
}

export const sagas = [
  takeEvery(ActionTypes.LOGIN_REQUEST, login),
  takeEvery(ActionTypes.TWO_FACTOR_CODE_REQUEST, sendTwoFactorCode),
  takeEvery(profileActionTypes.LOGOUT_SUCCESS, logoutSuccessSaga)
];
