import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { HelpLink } from "../../app/components/HelpLink";
import { TwoFactorMethodTypeMeta } from "../TwoFactorMethod";
import { resetTwoFactorRequest } from "../duck/actions";
import { twoFactorMethodsSelector } from "../duck/selectors";

const TWO_FACTOR_REQUEST_FORM = {
  NAME: "two-factor-request-form",
  FIELDS: {
    METHOD: "method",
  },
};

class TwoFactorRequestForm extends Component<Props, Record<string, unknown>> {
  render() {
    const { submitting, hasFailedV3Captcha, captchaV2Token, twoFactorMethods, change, handleSubmit, resetTwoFactor } = this.props;
    const disabled = submitting || (hasFailedV3Captcha && !captchaV2Token);

    return (
      <React.Fragment>
        <p className="text-center">
          In order to ensure your security, we will send you an access code. Please select how you would like to receive this code.
        </p>

        <form onSubmit={handleSubmit} className="mt-2">
          <div className="btn-group d-flex">
            {twoFactorMethods
              .sort((a, b) => TwoFactorMethodTypeMeta[ a ]?.sortIndex - TwoFactorMethodTypeMeta[ b ]?.sortIndex)
              .map(method => (
                <TwoFactorButton {...{ method, disabled, change }} />
              ))}
          </div>
        </form>

        <div className="d-flex justify-content-between mt-4">
          <button className="btn btn-link" onClick={resetTwoFactor}>
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            Go Back
          </button>

          <HelpLink />
        </div>
      </React.Fragment>
    );
  }
}

const TwoFactorButton = ({ method, disabled, change }: {
  method: string;
  disabled: boolean;
  change: InjectedFormProps[ "change" ];
}) => {
  const methodText = TwoFactorMethodTypeMeta[ method ]?.text;

  return (
    <button
      key={method}
      type="submit"
      disabled={disabled}
      onClick={() => change(TWO_FACTOR_REQUEST_FORM.FIELDS.METHOD, method)}
      className="btn btn-primary w-100 mx-1"
    >
      {methodText ?? method}
    </button>
  );
};

type MyProps = {
  captchaV2Token: string;
  hasFailedV3Captcha: boolean;
  storeCaptchaV2Token: (token: string) => void;
}

type InjectedProps = {
  twoFactorMethods: string[];
  formValues: any;
  formErrors: any;
};

type InjectedActions = {
  resetTwoFactor: typeof resetTwoFactorRequest;
};

type FormProps = {
  method: string;
};

type Props = MyProps & InjectedProps & InjectedActions & InjectedFormProps<FormProps>;

const mapStateToProps = state => {
  return {
    twoFactorMethods: twoFactorMethodsSelector(state),
    formValues: getFormValues(TWO_FACTOR_REQUEST_FORM.NAME)(state),
    formErrors: getFormSyncErrors(TWO_FACTOR_REQUEST_FORM.NAME)(state),
  };
};

const mapDispatchToProps = {
  resetTwoFactor: resetTwoFactorRequest,
};

export default reduxForm<any, any>({
  form: TWO_FACTOR_REQUEST_FORM.NAME, // a unique identifier for this form
  enableReinitialize: true,
  shouldValidate: () => true,
})(
  connect<InjectedProps, InjectedActions>(
    mapStateToProps,
    mapDispatchToProps
  )(TwoFactorRequestForm)
);
