import { HelpMode, publicClarakmConfig as clarakmConfig } from "@teslagov/clarakm-env-js";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export function HelpLink(): JSX.Element {
  const helpConfig = clarakmConfig.login.help;
  let component: JSX.Element = null;

  if (helpConfig != null) {
    if (helpConfig.mode === HelpMode.Form) {
      component = <Link to="/help">{t("login:help.title")}</Link>;
    }
    else if (helpConfig.mode === HelpMode.Link) {
      component = (
        <a href={helpConfig.url} target="_blank" rel="noopener noreferrer" >
          {t("login:help.title")}
        </a>
      );
    }
  }

  return component;
}
