export const REQUEST_ACCOUNT_REQUEST = "request-account/REQUEST_ACCOUNT_REQUEST";
export const REQUEST_ACCOUNT_SUCCESS = "request-account/REQUEST_ACCOUNT_SUCCESS";
export const SELF_REGISTRATION_SUCCESS = "request-account/SELF_REGISTRATION_SUCCESS";
export const REQUEST_ACCOUNT_FAILURE = "request-account/REQUEST_ACCOUNT_FAILURE";
export const CAPTCHA_V2_TOKEN = "request-account/CAPTCHA_V2_TOKEN";
export const CAPTCHA_V3_FAILURE = "request-account/CAPTCHA_V3_FAILURE";
export const SELF_REGISTRATION_FAILURE = "request-account/SELF_REGISTRATION_FAILURE";
export const SHOW_SUCCESS_MODAL = "request-account/SHOW_SUCCESS_MODAL";
export const HIDE_SUCCESS_MODAL = "request-account/HIDE_SUCCESS_MODAL";
export const CLEAR_SELF_REGISTRATION_FAILURE = "request-account/CLEAR_SELF_REGISTRATION_FAILURE";

export type ReducerState = {
  loading: boolean;
  errors: any;
  isModalShowing: boolean;
  isSelfRegistrationFailed: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

const initialState: ReducerState = {
  loading: false,
  errors: null,
  isModalShowing: false,
  isSelfRegistrationFailed: false,
  hasFailedV3Captcha: false,
  captchaV2Token: undefined,
};

export default function auth(state = initialState, action): ReducerState {
  switch (action.type) {
    case REQUEST_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case SELF_REGISTRATION_SUCCESS:
      return {
        ...state,
        isSelfRegistrationFailed: false,
        loading: false,
        errors: null,
      };
    case REQUEST_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSelfRegistrationFailed: false,
        loading: false,
        errors: null,
      };
    case SELF_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        isSelfRegistrationFailed: true,
      };
    case CAPTCHA_V3_FAILURE:
      return {
        ...state,
        loading: false,
        hasFailedV3Captcha: true,
      };
    case REQUEST_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case CAPTCHA_V2_TOKEN:
      return {
        ...state,
        captchaV2Token: action.token,
      };
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: true,
      };
    case HIDE_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: false,
      };
    case CLEAR_SELF_REGISTRATION_FAILURE:
      return {
        ...state,
        isSelfRegistrationFailed: false,
      };
    default:
      return state;
  }
}

export const requestAccountRequest = (token: string): { type: string, token: string } => ({ type: REQUEST_ACCOUNT_REQUEST, token });

export const storeCaptchaV2Token = (token: string): { type: string, token: string } => ({ type: CAPTCHA_V2_TOKEN, token });

export const captchaV3Failure = (): { type: string } => ({ type: CAPTCHA_V3_FAILURE });

export const selfRegistrationSuccess = (): { type: string } => ({ type: SELF_REGISTRATION_SUCCESS });

export const requestAccountSuccess = (): { type: string } => ({ type: REQUEST_ACCOUNT_SUCCESS });

export const selfRegistrationFailure = (errors: any): { type: string, errors: any } => ({ type: SELF_REGISTRATION_FAILURE, errors });

export const requestAccountFailure = (errors: any): { type: string, errors: any } => ({ type: REQUEST_ACCOUNT_FAILURE, errors });

export const showModal = (): { type: string } => ({ type: SHOW_SUCCESS_MODAL });

export const hideModal = (): { type: string } => ({ type: HIDE_SUCCESS_MODAL });

export const clearSelfRegistrationFailure = (): { type: string } => ({ type: CLEAR_SELF_REGISTRATION_FAILURE });
