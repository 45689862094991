import { RAMPART_API } from "../app";

export function sendPasswordResetEmail(payload) {
  return RAMPART_API.put("password-reset/email", payload)
    .then(response => ({ response }))
    .catch(error => ({ error }));
}

export function resetPassword(payload) {
  if (payload.newPassword !== payload.newPasswordConfirm) {
    return ({ error: "Password mismatch!" });
  }

  return RAMPART_API.put("/password-reset", payload)
    .then(response => ({ response }))
    .catch(error => ({ error }));
}
