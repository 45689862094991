import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { extractParametersFromQuerystring } from "@teslagov/clarakm-js-api";
import { setTitle } from "@teslagov/clarakm-js-react";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createStructuredSelector } from "reselect";
import { Errors } from "../../app/components/Errors";
import { HelpLink } from "../../app/components/HelpLink";
import { CAPTCHA_ACTIONS } from "../../app/constants";
import { executeCallbackWithCaptchaIfNecessary } from "../../app/utils";
import NewPasswordConfirmationForm from "../components";
import {
  hideModal,
  resetPasswordWithCodeRequest,
  sendResetPasswordCodeViaEmailRequest,
  storeCaptchaV2Token
} from "../duck";
import {
  captchaV2TokenSelector, codeSelector, errorsSelector,
  hasFailedV3CaptchaSelector,
  isModalShowingSelector,
  loadingSelector,
  newPasswordConfirmSelector,
  newPasswordSelector, resetEmailAddressSelector, resetTokenSelector
} from "../selectors";

class PasswordResetContainer extends Component<Props, State> {
  componentDidMount() {
    setTitle(t("resetPassword.title"));
  }

  handleSubmit = e => {
    const { resetPasswordWithCodeRequest, captchaV2Token, hasFailedV3Captcha, resetEmailAddress, code, resetToken, newPassword, newPasswordConfirm } = this.props;

    const onSubmit = (captchaToken: string) => resetPasswordWithCodeRequest({ emailAddress: resetEmailAddress, code, token: resetToken, newPassword, newPasswordConfirm, captchaToken, isV2Captcha: hasFailedV3Captcha });

    e.preventDefault();
    executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.resetPassword, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };

  render() {
    const { errors, isModalOpen, hasFailedV3Captcha, storeCaptchaV2Token, captchaV2Token, hideModal } = this.props;
    const { helpEmail } = clarakmConfig.app;
    const querystringParams = extractParametersFromQuerystring(document.location.href);
    const resetToken = querystringParams?.token;

    return (
      <div className="card mx-auto p-4">
        <div className="card-block">
          <h4 className="card-title">
            {t("resetPassword.title")}
          </h4>

          {errors && <Errors errors={errors} />}

          <NewPasswordConfirmationForm
            handleSubmit={this.handleSubmit}
            initialValues={{ resetToken }}
            hasFailedV3Captcha={hasFailedV3Captcha}
            storeCaptchaV2Token={storeCaptchaV2Token}
            captchaV2Token={captchaV2Token}
          />

          <div>
            <p>{t("resetPassword.reqs.leadIn")}</p>
            <ul>
              <li>{t("resetPassword.reqs.lowercase")}</li>
              <li>{t("resetPassword.reqs.uppercase")}</li>
              <li>{t("resetPassword.reqs.number")}</li>
              <li>{t("resetPassword.reqs.symbol")}</li>
            </ul>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <Link to="/login">
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
              {t("actions.goBack")}
            </Link>

            <HelpLink />
          </div>

          <Modal isOpen={isModalOpen} toggle={hideModal} ariaHideApp={false}>
            <ModalHeader>
              {t("resetPassword.sentModal.header")}
            </ModalHeader>
            <ModalBody dangerouslySetInnerHTML={{ __html: t("resetPassword.sentModal.body", { helpEmail }) }} />
            <ModalFooter>
              <button type="button" className="btn btn-link" onClick={() => hideModal()}>
                {t("actions.close")}
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

type Props = InjectedProps & InjectedActions;

type InjectedProps = {
  errors: any;
  resetEmailAddress: string;
  newPassword: string;
  newPasswordConfirm: string;
  code: string;
  resetToken: string;
  isModalOpen: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

type InjectedActions = {
  resetPasswordWithCodeRequest: typeof resetPasswordWithCodeRequest;
  hideModal: typeof hideModal;
  storeCaptchaV2Token: typeof storeCaptchaV2Token;
};

type State = Record<string, unknown>;

const mapStateToProps = createStructuredSelector({
  errors: errorsSelector,
  loading: loadingSelector,
  resetEmailAddress: resetEmailAddressSelector,
  newPassword: newPasswordSelector,
  newPasswordConfirm: newPasswordConfirmSelector,
  code: codeSelector,
  resetToken: resetTokenSelector,
  isModalOpen: isModalShowingSelector,
  hasFailedV3Captcha: hasFailedV3CaptchaSelector,
  captchaV2Token: captchaV2TokenSelector,
});

const mapDispatchToProps = {
  sendResetPasswordCodeViaEmailRequest,
  resetPasswordWithCodeRequest,
  hideModal,
  storeCaptchaV2Token,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer);
