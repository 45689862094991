import React from "react";
import classNames from "classnames";

const TextArea = ({ autoFocus, name, inputDivClassnames, inputClassNames, input, rows, maxLength }) => {
  return (
    <div className={inputDivClassnames}>
      <textarea maxLength={maxLength} autoFocus={autoFocus} id={`${name}-input`} className={classNames("form-control", "clarakm-input", inputClassNames)} aria-describedby={`${name}-label`} rows={rows} {...input} />
    </div>
  );
};

export default TextArea;
