import { v4 as uuidGen } from "uuid";

const deviceIdKey = "deviceId";
const oidcRequestIdKey = "oidcRequestId";

export function getDeviceId() {
  let deviceId = localStorage.getItem(deviceIdKey);

  if (deviceId == null) {
    deviceId = uuidGen();
    localStorage.setItem(deviceIdKey, deviceId);
  }

  return deviceId;
}

export function setOIDCRequestId(requestId) {
  localStorage.setItem(oidcRequestIdKey, requestId);
}

export function getOIDCRequestId() {
  const requestId = localStorage.getItem(oidcRequestIdKey);

  localStorage.removeItem(oidcRequestIdKey);

  return requestId;
}
