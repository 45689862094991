import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { captchaInfoToastr } from "../app/constants";
import { submitHelpRequest } from "./api";
import {
  CAPTCHA_V2_TOKEN, helpSubmissionCaptchaFailure, helpSubmissionFailure, helpSubmissionRequest,
  helpSubmissionSuccess, HELP_SUBMISSION_REQUEST, showModal, storeCaptchaV2Token
} from "./duck";
import {
  captchaV2TokenSelector, descriptionSelector,
  emailAddressSelector, hasFailedV3CaptchaSelector, nameSelector
} from "./selectors";

export function* helpSubmission(action: ReturnType<typeof helpSubmissionRequest>) {
  const name = yield select(nameSelector);
  const emailAddress = yield select(emailAddressSelector);
  const description = yield select(descriptionSelector);
  const hasFailedV3Captcha = yield select(hasFailedV3CaptchaSelector);
  const captchaV2Token = yield select(captchaV2TokenSelector);
  const captchaToken = hasFailedV3Captcha ? captchaV2Token : action.token;

  try {
    const body = {
      name,
      emailAddress,
      description,
      subject: "Help Request",
      captchaToken,
      isV2Captcha: hasFailedV3Captcha, // TODO !!!!!
    };

    yield call(submitHelpRequest, body);
    yield put(helpSubmissionSuccess());
    yield put(showModal());
  }
  catch (errorResponse: any) {
    if (errorResponse?.response?.status === 403) {
      yield call(captchaInfoToastr);
      yield put(helpSubmissionCaptchaFailure(errorResponse));
    }
    else {
      yield put(helpSubmissionFailure(errorResponse));
    }
  }
}

export function* helpSubmissionSaga() {
  yield takeEvery(HELP_SUBMISSION_REQUEST, helpSubmission);
}

export function* storeCaptchaToken(action: ReturnType<typeof storeCaptchaV2Token>) {
  const token = action.token;

  yield call(storeCaptchaV2Token, token);
}

export function* storeCaptchaV2TokenSaga() {
  yield takeEvery(CAPTCHA_V2_TOKEN, storeCaptchaToken);
}

export const sagas = [ fork(helpSubmissionSaga), fork(storeCaptchaV2TokenSaga) ];
