import PasswordValidator from "password-validator";
import getStore from "../app/store";
import { getFormValues } from "redux-form";
import { FORM_NAMES, FORM_FIELD_NAMES } from "../password-reset/constants";
import emailCheck from "validator/lib/isEmail";
import { clarakmConfig } from "@teslagov/clarakm-env-js";

const passwordRequirements = clarakmConfig.app.password.requirements;
const passWordSchema = new PasswordValidator();
passWordSchema
  .is()
  .min(passwordRequirements.minLength)
  .is()
  .max(passwordRequirements.maxLength);
if (passwordRequirements.uppercase) {
  passWordSchema.has().uppercase();
}
if (passwordRequirements.lowercase) {
  passWordSchema.has().lowercase();
}
if (passwordRequirements.symbols) {
  passWordSchema.has().symbols();
}
if (passwordRequirements.digits) {
  passWordSchema.has().digits();
}

const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const maxLength16 = maxLength(16);

const isEmail = value => {
  if (!value) {
    return "Invalid email address";
  }

  //email regex
  if (!emailCheck(value)) {
    return "Invalid email address";
  }

  return undefined;
};

const validPassword = value => {
  if (!value || typeof value !== "string") {
    return undefined;
  }

  const validationErrors = passWordSchema.validate(value, { list: true });

  if (validationErrors.length) {
    const error = validationErrors[0];
    let errorMsg;

    switch (error) {
      case "min":
        errorMsg = `Password cannot be shorter than ${passwordRequirements.minLength} characters`;
        break;
      case "max":
        errorMsg = `Password cannot be longer than ${passwordRequirements.maxLength} characters`;
        break;
      case "uppercase":
        errorMsg = "The password must have at least 1 uppercase character";
        break;
      case "lowercase":
        errorMsg = "The password must have at least 1 lowercase character";
        break;
      case "symbols":
        errorMsg = "The password must have at least 1 symbol character";
        break;
      default:
        errorMsg = "The password must have at least 1 digit character";
        break;
    }
    return errorMsg;
  }

  return undefined;
};

const passWordMatch = value => {
  const password = getFormValues(FORM_NAMES.passwordResetNewPasswordForm)(getStore().getState())[FORM_FIELD_NAMES.newPassword];
  if (password && value && value !== password) {
    return "Passwords do not match";
  }

  return undefined;
};

const validResetCode = value => {
  const codeFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  if (value && !codeFormat.test(value.trim())) {
    return "Invalid code. Be sure to copy and paste the code exactly as it appears in the password reset email.";
  }
  return undefined;
};

export { maxLength, isEmail, validPassword, passWordMatch, maxLength16, validResetCode };
