import React from "react";

const Label = ({ name, label, labelDivClassnames, labelClassnames }) => {
  return (
    <div className={labelDivClassnames}>
      <label id={`${name}-label`} className={labelClassnames} htmlFor={`${name}-input`}>
        {label}
      </label>
    </div>
  );
};

export default Label;
