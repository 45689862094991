import { Title } from "@teslagov/clarakm-js-react";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Errors } from "../../app/components/Errors";
import { CAPTCHA_ACTIONS } from "../../app/constants";
import { executeCallbackWithCaptchaIfNecessary } from "../../app/utils";
import { EmailForm } from "../components";
import { sendResetPasswordCodeViaEmailRequest, storeCaptchaV2Token } from "../duck";
import { captchaV2TokenSelector, errorsSelector, hasFailedV3CaptchaSelector, loadingSelector } from "../selectors";

class ForgotPasswordContainer extends Component<Props, State> {
  handleEmailFormSubmit = e => {
    const { sendResetPasswordCodeViaEmailRequest, captchaV2Token, hasFailedV3Captcha } = this.props;
    const onSubmit = (token: string) => sendResetPasswordCodeViaEmailRequest(token);

    e.preventDefault();
    executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.forgotPassword, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };

  render() {
    const { errors, loading, storeCaptchaV2Token, hasFailedV3Captcha, captchaV2Token } = this.props;
    const handleSubmit = this.handleEmailFormSubmit;

    return (
      <div className="card mx-auto p-4">
        <Title value={t("resetPassword.title")} />
        <div className="card-block">
          <h4 className="card-title">{t("resetPassword.title")}</h4>
          {errors && <Errors errors={errors} />}
          <EmailForm {...{ handleSubmit, loading, storeCaptchaV2Token, hasFailedV3Captcha, captchaV2Token }} />
        </div>
      </div>
    );
  }
}

type Props = InjectedProps & InjectedActions;

type InjectedProps = {
  errors: any[];
  loading: boolean;
  hasFailedV3Captcha: boolean,
  captchaV2Token: string,
};

type InjectedActions = {
  sendResetPasswordCodeViaEmailRequest: typeof sendResetPasswordCodeViaEmailRequest;
  storeCaptchaV2Token: typeof storeCaptchaV2Token;
};

type State = Record<string, unknown>;

const mapStateToProps = createStructuredSelector({
  errors: errorsSelector,
  loading: loadingSelector,
  hasFailedV3Captcha: hasFailedV3CaptchaSelector,
  captchaV2Token: captchaV2TokenSelector,
});

const mapDispatchToProps = {
  sendResetPasswordCodeViaEmailRequest,
  storeCaptchaV2Token,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
