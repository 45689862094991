import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { required } from "@teslagov/clarakm-js-api";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, getFormValues, reduxForm } from "redux-form";
import { Captcha } from "../../authorize/Captcha";
import { isEmail, maxLength, passWordMatch, renderField, validPassword, validResetCode } from "../../redux-form";
import { FORM_FIELD_NAMES, FORM_NAMES } from "../constants";

const emailFormValidation = [ required({ messages: { label: "Email Address" } }), maxLength(255), isEmail ];

const EmailForm = reduxForm<Record<string, unknown>, EmailFormProps>({
  form: FORM_NAMES.passwordResetEmailForm,
  touchOnChange: true,
})(({ handleSubmit, valid, loading, storeCaptchaV2Token, hasFailedV3Captcha, captchaV2Token }) => (
  <>
    <form onSubmit={handleSubmit} className="mt-2">
      <Field<any> name={FORM_FIELD_NAMES.emailAddress} fieldName={FORM_FIELD_NAMES.emailAddress} label={t("labels.emailAddress")} validate={emailFormValidation} component={renderField} inputClassNames="mb-0" labelClassnames="py-1 required-label mb-0" />
      <Captcha hasFailedV3Captcha={hasFailedV3Captcha} storeCaptchaV2Token={storeCaptchaV2Token} />
      <div className="text-center mt-5 mb-5">
        <button disabled={loading || !valid || (hasFailedV3Captcha && !captchaV2Token)} type="submit" className="btn btn-primary">
          {t("resetPassword.actions.sendCode")}
        </button>
      </div>
    </form>

    <div className="d-flex justify-content-between mt-4">
      <Link to="/login">
        <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        {t("actions.goBack")}
      </Link>

      <Link to="/password-confirm">
        <span className="mx-auto">
          {t("resetPassword.actions.haveCode")}
        </span>
      </Link>
    </div>
  </>
));

type EmailFormProps = {
  handleSubmit: any;
  loading: boolean;
  storeCaptchaV2Token: (token: string) => void;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
}

class NewPasswordConfirmationForm extends Component<NewPasswordConfirmationFormProps> {
  requiredValidator = required({ messages: { label: "" } });

  render() {
    const { handleSubmit, invalid, submitting, hasFailedV3Captcha, storeCaptchaV2Token, captchaV2Token, formValues } = this.props;
    const { requiredValidator } = this;

    return (
      <form className="mt-2">
        <Field<any> name={FORM_FIELD_NAMES.emailAddress} fieldName={FORM_FIELD_NAMES.emailAddress} label={t("labels.emailAddress")} validate={emailFormValidation} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />

        {!(formValues?.resetToken) &&
          <Field<any> normalize={value => value.trim()} name={FORM_FIELD_NAMES.code} autoComplete="off" fieldName={FORM_FIELD_NAMES.code} label={t("resetPassword.labels.passwordResetCode")} validate={[ requiredValidator, validResetCode ]} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />
        }

        <Field<any> name={FORM_FIELD_NAMES.newPassword} fieldName={FORM_FIELD_NAMES.newPassword} type="password" label={t("resetPassword.labels.newPassword")} validate={[ validPassword, requiredValidator ]} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />
        <Field<any> name={FORM_FIELD_NAMES.confirmNewPassword} fieldName={FORM_FIELD_NAMES.confirmNewPassword} type="password" label={t("resetPassword.labels.confirmPassword")} validate={[ requiredValidator, passWordMatch ]} component={renderField} inputClassNames="mb-0" labelClassnames="required-label" />
        <Captcha hasFailedV3Captcha={hasFailedV3Captcha} storeCaptchaV2Token={storeCaptchaV2Token} />
        <div className="d-flex justify-content-center mt-5 mb-5">
          <button disabled={invalid || submitting || (hasFailedV3Captcha && !captchaV2Token)} onClick={handleSubmit} type="submit" className="btn btn-primary">
            <span className="mx-auto">
              {t("actions.submit")}
            </span>
          </button>
        </div>
      </form>
    );
  }
}

type InjectedProps = {
  formValues: any;
};

type NewPasswordConfirmationFormProps = InjectedProps & {
  handleSubmit: any;
  invalid: any;
  submitting: any;
  storeCaptchaV2Token: (token: string) => void;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

const mapStateToProps = state => {
  return {
    formValues: getFormValues(FORM_NAMES.passwordResetNewPasswordForm)(state),
  };
};

export default reduxForm<any, any>({
  form: FORM_NAMES.passwordResetNewPasswordForm,
  touchOnChange: true,
})(
  connect<InjectedProps>(
    mapStateToProps
  )(NewPasswordConfirmationForm)
);

export { EmailForm };
