export const HELP_SUBMISSION_REQUEST = "help/HELP_SUBMISSION_REQUEST";
export const HELP_SUBMISSION_SUCCESS = "help/HELP_SUBMISSION_SUCCESS";
export const HELP_SUBMISSION_FAILURE = "help/HELP_SUBMISSION_FAILURE";
export const SHOW_SUCCESS_MODAL = "help/SHOW_SUCCESS_MODAL";
export const HIDE_SUCCESS_MODAL = "help/HIDE_SUCCESS_MODAL";
export const CAPTCHA_V3_FAILURE = "help/CAPTCHA_V3_FAILURE";
export const CAPTCHA_V2_TOKEN = "help/CAPTCHA_V2_TOKEN";

export type ReducerState = {
  loading: boolean;
  errors: any;
  isModalShowing: boolean;
  isLoggedIn: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

const initialState: ReducerState = {
  loading: false,
  errors: null,
  isModalShowing: false,
  isLoggedIn: false,
  hasFailedV3Captcha: false,
  captchaV2Token: undefined,
};

export default function auth(state = initialState, action): ReducerState {
  switch (action.type) {
    case HELP_SUBMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case HELP_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case HELP_SUBMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case CAPTCHA_V3_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        hasFailedV3Captcha: true,
      };
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: true,
      };
    case HIDE_SUCCESS_MODAL:
      return {
        ...state,
        isModalShowing: false,
      };
    case CAPTCHA_V2_TOKEN:
      return {
        ...state,
        captchaV2Token: action.token,
      };
    default:
      return state;
  }
}

export const helpSubmissionRequest = (token: string): { type: string, token: string } => ({ type: HELP_SUBMISSION_REQUEST, token });

export const storeCaptchaV2Token = (token: string): { type: string, token: string } => ({ type: CAPTCHA_V2_TOKEN, token });

export const helpSubmissionSuccess = (): { type: string } => ({ type: HELP_SUBMISSION_SUCCESS });

export const helpSubmissionFailure = (errors: any): { type: string; errors: any } => ({ type: HELP_SUBMISSION_FAILURE, errors });

export const helpSubmissionCaptchaFailure = (errors: any): { type: string; errors: any } => ({ type: CAPTCHA_V3_FAILURE, errors });

export const showModal = (): { type: string } => ({ type: SHOW_SUCCESS_MODAL });

export const hideModal = (): { type: string } => ({ type: HIDE_SUCCESS_MODAL });
