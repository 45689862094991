import { getBaseApiUrl } from "@teslagov/clarakm-js-api";
import { initClaraToastr } from "@teslagov/clarakm-js-react";
import axios from "axios";
import { toastr } from "react-redux-toastr";

axios.defaults.headers.common["Cache-Control"] = "no-cache";

export const APP_KEY = "web";
export const RAMPART_API = axios.create({
  baseURL: getBaseApiUrl("rampart"),
  withCredentials: true,
});
export const getZendeskApi = () =>
  axios.create({
    baseURL: getBaseApiUrl("zendesk"),
    withCredentials: true,
  });
export const claraToastr = initClaraToastr(toastr);

export function captchaInfoToastr() {
  claraToastr.info("User Authentication Required", "Please complete the reCAPTCHA below to continue.");
}

export const nameof = <T>(name: keyof T) => name;

export const LOGIN_TABS = {
  signIn: "sign-in",
  requestAccount: "request-account",
};

export const CAPTCHA_ACTIONS = {
  requestAccount: "request_account",
  helpRequest: "help_request",
  resetPassword: "reset_password",
  forgotPassword: "forgot_password",
  forgotUsername: "forgot_username",
  login: "login",
  twoFactor: "two_factor",
};
