import { NAME, FORM_NAME, FORM_FIELD_NAMES } from "./constants";
import { formValueSelector } from "redux-form";
import { ReducerState } from "./duck";

const reducerSelector = (state): ReducerState => state[NAME];

export const errorsSelector = state => reducerSelector(state).errors;
export const loadingSelector = state => reducerSelector(state).loading;
export const isModalShowingSelector = state => reducerSelector(state).isModalShowing;
export const hasFailedV3CaptchaSelector = state => reducerSelector(state).hasFailedV3Captcha;
export const captchaV2TokenSelector = state => reducerSelector(state).captchaV2Token;

const formSelector = formValueSelector(FORM_NAME);
export const nameSelector = state => formSelector(state, FORM_FIELD_NAMES.name);
export const emailAddressSelector = state => formSelector(state, FORM_FIELD_NAMES.emailAddress);
export const descriptionSelector = state => formSelector(state, FORM_FIELD_NAMES.description);
