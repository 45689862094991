export enum TwoFactorMethod {
  Email = "email",
  TextMessage = "text",
  PhoneCall = "phone",
}

export const TwoFactorMethodTypeMeta = {
  [ TwoFactorMethod.Email ]: {
    text: "Email",
    sortIndex: 0,
  },
  [ TwoFactorMethod.TextMessage ]: {
    text: "Text Message",
    sortIndex: 1,
  },
  [ TwoFactorMethod.PhoneCall ]: {
    text: "Phone Call",
    sortIndex: 2,
  },
};
