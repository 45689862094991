import React, { Component } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { loadRecaptcha } from "../utils";

export default class RootContainer extends Component {
  componentDidMount() {
    loadRecaptcha();
  }

  render() {
    const { router, store } = this.props;

    return (
      <Provider store={store}>
        <div className="p-4">
          {router}
          <ReduxToastr timeOut={10000} newestOnTop={false} preventDuplicates position="top-center" />
        </div>
      </Provider>
    );
  }
}
