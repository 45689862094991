import { APP_REDUCER_KEY as CLARAKM_REDUCER_KEY, appReducer as clarakmReducer } from "@teslagov/clarakm-js-react";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { constants as helpConstants, reducer as helpReducer } from "./help";
import { constants as loginRequestConstants, reducer as loginRequestReducer } from "./login";
import { constants as loginNavConstants, reducer as loginNavReducer } from "./login-nav";
import { constants as passwordResetConstants, reducer as passwordResetReducer } from "./password-reset";
import { constants as requestAccountConstants, reducer as requestAccountReducer } from "./request-account";

import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers<any>({
  [CLARAKM_REDUCER_KEY]: clarakmReducer,
  router: routerReducer,
  form,
  [loginNavConstants.NAME]: loginNavReducer,
  [loginRequestConstants.NAME]: loginRequestReducer,
  [helpConstants.NAME]: helpReducer,
  [requestAccountConstants.NAME]: requestAccountReducer,
  [passwordResetConstants.NAME]: passwordResetReducer,
  toastr: toastrReducer,
});
