import { NAME } from "../constants";
import { ReducerState } from "./reducer";

const reducerStateSelector = (state): ReducerState => state[NAME];

//TODO: This should be removed, most likely invalid.
export const errorsSelector = state => state[NAME].errors;

export const isLoggedInSelector = state => reducerStateSelector(state).isLoggedIn;

export const passwordExpiredSelector = state => reducerStateSelector(state).passwordExpired;
export const twoFactorRequiredSelector = state => reducerStateSelector(state).twoFactorRequired;
export const twoFactorMethodsSelector = state => reducerStateSelector(state).twoFactorMethods;
export const twoFactorCodeSentSelector = state => reducerStateSelector(state).twoFactorCodeSent;
